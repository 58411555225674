import React from "react";
import LayoutPanel from 'components/layout/layoutPanel';

import { LinkModal } from 'kh-common-components';

import { modalContents } from 'constants/modal-content';
import heroBanner from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import 'styles/pages/ng-rac-promo-info.css';

function NgRacPromoInfo (props) {

    return (
    
        <LayoutPanel background={props.panelBackground}
                    padding={props.panelPadding}
                    {...props}
                    id="racbonus50-ng-info">
            <div className="container">
                <div className="row mer-panel align-items-center justify-content-around flex-lg-column">

                    <div className="mer-panel__images col-8 col-sm-5 col-lg-3 my-0 pt-0">
                        <img src={heroBanner} 
                            className="mer-panel__image kleenheat-rac-logo"
                            alt="RAC logo" />
                    </div>

                    <div className="col-12 col-sm-10 col-lg-7 pt-0 text-center mer-text--size-lg">
                        <h3 className="mer-panel__title mer-typography--headline1 my-0">RAC member exclusive offer</h3>
                        <h5>Switch to Kleenheat and save 38% off natural gas usage charges for 12 months, plus get a $50 account credit.<sup>*</sup></h5>
                        <p className="mer-typography--body2"><sup>*</sup>Offer available to new customers only.&nbsp;
                            <LinkModal 
                                modalContents={modalContents}
                                useModal="true"
                                displayContent="Terms and conditions apply."
                                title="RAC offer terms and conditions"
                                modalTitle="RAC offer terms and conditions"
                                modalContentName="racbonus50-ng-terms"
                                okayButtonLabel="Close"
                            />
                        </p>

                        <div className="col-12 col-sm-10 col-lg-5 mer-panel__actions mer-button-group flex-column mx-auto mt-0">
                            <a href="/rac/natural-gas"
                                title="Go to natural gas page"
                                className="mer-button mer-button--primary">
                                Learn more
                            </a>
                    </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
  )
}

export default NgRacPromoInfo;